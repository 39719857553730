<template>
  <div class="inquiry-contact">
    <div class="inquiryDetail_line"></div>
    <div class="inquiry-content">
      <!-- <div class="title">
        {{ $t("contact") }}
      </div> -->
      <div class="content-wrap">
        <div class="area">
          <div class="departure">
            <span class="text1">{{ $t("name") }}</span>
            <span class="text2">
              {{ info.name | textFormat }}
            </span>
          </div>
          <div class="destination">
            <span class="text1">{{ $t("position") }}</span>
            <span class="text2"> {{ info.job_title | textFormat }}</span>
          </div>
          <div class="name">
            <span class="text1">{{ $t("company") }}</span>
            <span class="text2">
              {{ info.company | textFormat }}
            </span>
          </div>
          <div class="weight">
            <span class="text1">{{ $t("address") }}</span>
            <span class="text2" v-if="LOCALE == 'en' ">
              <span v-if="info.address">
                    {{info.address}}
                </span>
                <span>
                  {{
                  info.country_id
                    | countryCityFormat(info.region_en, info.region_zh, LOCALE)
                }}
                </span>
            </span>
            <span class="text2" v-else>
              {{
                  info.country_id
                    | countryCityFormat(info.region_en, info.region_zh, LOCALE)
                }}
                <span v-if="info.address">
                    {{info.address}}
                </span>
            </span>
          </div>
          <div class="volume">
            <span class="text1">{{ $t("email") }}</span>
            <span class="text2" style="230">
              {{ info.email | textFormat }}
            </span>
          </div>
        </div>
        <div class="time">
          <div class="delivery-time">
            <span class="text1">{{ $t("telephone") }}</span>
            <span class="text2">
              {{info.phone | phoneFormat(info.area_code)}}
            </span>
          </div>
          <div class="arrival-time">
            <span class="text1">{{ $t("facebook") }}</span>
            <span class="text2">
              {{ info.facebook | textFormat }}
            </span>
          </div>
          <div class="shipping-mode">
            <span class="text1">{{ $t("skype") }}</span>
            <span class="text2">
              {{ info.skype | textFormat }}
            </span>
          </div>
          <div class="pack">
            <span class="text1">{{ $t("wechat") }}</span>
            <span class="text2">
              {{ info.wechat | textFormat }}
            </span>
          </div>
          <div class="clause">
            <span class="text1">{{ $t("qq") }}</span>
            <span class="text2">
              {{ info.qq | textFormat }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "inquiryContact",
  props: {
    info: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  created() {
   
  }
};
</script>

<style lang="less" scoped>
.inquiry-contact {
  width: 100%;
  background: rgba(255, 255, 255, 1);
  .inquiryDetail_line {
    border: 1px solid #ECECEC;
    // width: 100%;
    // height: 5px;
    // background: url("https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/images/inquiryDetailLine.png");

  }
  .inquiry-content {
    padding: 30px 69px;
    .title {
      font-size: 16px;
      font-weight: bold;
      color: rgba(51, 51, 51, 1);
      line-height: 22px;
    }
    .content-wrap {
      > div {
        display: flex;
        box-sizing: border-box;
        margin-top: 30px;
        padding-bottom: 30px;
        border-bottom: 1px dashed #cbcbcb;
        div {
          display: flex;
          flex-direction: column;
          span:nth-child(1) {
            margin-bottom: 20px;
          }
        }
      }
      > div:last-child {
        border-bottom: none;
      }
      .departure,
      .delivery-time {
        width: 270px;
      }
      .destination,
      .arrival-time {
        width: 249px;
      }
      .destination {
        .text2 {
          width: 230px;
        }
      }
      .name,
      .shipping-mode {
        width: 230px;
      }
      .weight,
      .pack {
        width: 262px;
      }
      .volume,
      .clause,
      .trailer {
        width: 157px;
      }
      span {
        word-break: break-all;
      }
      .name {
        .text2 {
          width: 200px;
        }
      }
      .weight{
        .text2{
          width: 240px;
        }
      }
    }
  }
}
.text1 {
  font-size: 14px;
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
  line-height: 20px;
}
.text2 {
  font-size: 14px;
  font-weight: 500;
  color: rgba(51, 51, 51, 1);
  line-height: 20px;
}
</style>
