<template>
  <div class="inquirt_detail">
    <div class="inquiryDetail_line"></div>
    <div class="inquiry_content">
      <!-- <div class="title">{{$t('cargo')}}</div> -->
      <div class="content-wrap">
        <div class="area">
          <div class="departure">
            <span class="text1">{{ $t("departure") }}</span>
            <span class="text2">
              {{info.from_country|countryCityFormat(info.from_region_en,info.from_region_zh,LOCALE)}}
            </span>
          </div>
          <div class="destination">
            <span class="text1">{{ $t("destination") }}</span>
            <span class="text2">
              <span class="text2">
                {{info.arrival_country|countryCityFormat(info.arrival_region_en,info.arrival_region_zh,LOCALE)}}
              </span>
            </span>
          </div>
          <div class="name">
            <span class="text1">{{ $t("commodity") }}</span>
            <span class="text2">{{ info.cargo_name | textFormat }}</span>
          </div>
          <div class="weight">
            <span class="text1">{{ $t("totalWeight") }}</span>
            <span class="text2">
              {{ info.weight }}
              {{ info.weight_unit | weightUnitFormat(LOCALE) }}
            </span>
          </div>
          <div class="volume">
            <span class="text1">{{ $t("volume") }}（L×W×H）</span>
            <span class="text2" v-if="info.volume||info.length||info.height||info.width" >
              <span v-if="info.volume">
                {{ info.volume }}{{ info.volume_unit | volumeUnitFormat(LOCALE) }}
              </span>
              <span
                v-if="info.length||info.width||info.height"
              >
                ({{ info.length | numberFormat }}*{{
                info.width | numberFormat
                }}*{{ info.height | numberFormat
                }}{{ info.dimension_unit | dimensionUnitFormat(LOCALE) }})
              </span>
            </span>
            <span v-else>-</span>
          </div>
        </div>
        <div class="time">
          <div class="delivery-time">
            <span class="text1">{{ $t("sendTime") }}</span>
            <span class="text2">{{ info.delivery_time | secondFormat("LL") }}</span>
          </div>
          <div class="arrival-time">
            <span class="text1">{{ $t("arrivalTime") }}</span>
            <span class="text2">{{ info.arrival_time | secondFormat("LL") }}</span>
          </div>
          <div class="shipping-mode">
            <span class="text1">{{ $t("shippingMode") }}</span>
            <span class="text2">{{ info.shipping_mode | shipModeFormat(LOCALE) }}</span>
          </div>
          <div class="pack">
            <span class="text1">{{ $t("packed") }}</span>
            <span class="text2">{{ info.pack_type | packedFormat(LOCALE) }}</span>
          </div>
          <div class="clause">
            <span class="text1">{{ $t("Incoterms") }}</span>
            <span class="text2">{{ info.incoterms | incotermsFormat }}</span>
          </div>
        </div>
        <div class="check">
          <div class="danger">
            <span class="text1">{{ $t("dangerousGoods") }}</span>
            <p class v-if="!info.is_hazardous">{{ $t("no") }}</p>
            <p class v-if="info.is_hazardous == 1 && info.hazardousStatus"></p>
            <p class v-if="info.is_hazardous == 1 && !(info.hazardous_info.level||info.hazardous_info.proper_nouns||info.hazardous_info.un_nunber||info.hazardous_info.pack_level||info.hazardous_info.flash_point_temperature||info.hazardous_info.msds_list.length)">{{ $t("yes") }}</p>
            <p
              class
              v-if="info.hazardous_info.level"
            >{{ $t("cargoMoreDangesLeve") }}：{{ info.hazardous_info.level | dangerousLevelFormat(LOCALE) }}</p>
            <p class v-if="info.hazardous_info.proper_nouns">
              {{ $t("properNoun") }}：{{
              info.hazardous_info.proper_nouns
              }}
            </p>
            <p class v-if="info.hazardous_info.un_nunber">
              {{ $t("serialNumber") }}：{{
              info.hazardous_info.un_nunber
              }}
            </p>
            <p class v-if="info.hazardous_info.pack_level">
              {{ $t("Packinggroup") }}：{{
              info.hazardous_info.pack_level | packageLevelFormat
              }}
            </p>
            <p v-if="info.hazardous_info.flash_point_temperature">
              {{ $t("ignitionture") }}：{{
              info.hazardous_info.flash_point_temperature
              }}
              {{ info.hazardous_info.temperature_unit | temperatureUnitFormat(LOCALE) }}
            </p>
            <p
              class="clickFile"
              v-if="info.hazardous_info && info.hazardous_info.msds_list.length!=0"
            >
              {{ $t("file") }}：
              <a :href="info.hazardous_info.msds_list[0].url">
                <img
                  :src="
                  info.hazardous_info.msds_list[0].file_type
                    | enclosureImgbyTypeFormat
                "
                  alt
                  class="fileImg marginLess"
                />
                {{ info.hazardous_info.msds_list[0].file_name }}
              </a>
            </p>
          </div>
          <div class="temperature">
            <span class="text1">{{ $t("temperatureGoods") }}</span>
            <p v-if="!info.is_temperature">{{ $t("no") }}</p>
            <p v-if="info.is_temperature == 1 && info.temperatureStatus"></p>
            <p v-if="info.is_temperature == 1 && !(info.temperature_info.cooling||info.temperature_info.desc||info.temperature_info.humidity||info.temperature_info.packaging||info.temperature_info.range)">{{ $t("yes") }}</p>
            <p
              v-if="info.temperature_info.desc"
            >{{$t('description')}}：{{ info.temperature_info.desc }}</p>
            <p v-if="info.temperature_info.range">
              {{$t('Range')}}：{{ info.temperature_info.range }}
              {{
              info.temperature_info.temperature_unit | temperatureUnitFormat(LOCALE)
              }}
            </p>
            <p
              v-if="info.temperature_info.cooling"
            >{{$t('cooling')}}：{{ info.temperature_info.cooling | coolingFormat }}</p>
            <p
              v-if="info.temperature_info.packaging"
            >{{$t('Packaging')}}：{{ info.temperature_info.packaging }}</p>
            <p
              v-if="info.temperature_info.humidity"
            >{{$t('Humidity')}}：{{ info.temperature_info.humidity }}</p>
          </div>
          <div class="stackable">
            <span class="text1">{{ $t("nonStackable") }}</span>
            <p v-if="!info.is_stackable">{{ $t("no") }}</p>
            <p v-if="info.is_stackable == 1">{{ $t("yes") }}</p>
          </div>
          <div class="customs">
            <span class="text1">{{ $t("needDeclare") }}</span>
            <p class v-if="!info.is_declare_customs">{{ $t("no") }}</p>
            <p class v-if="info.is_declare_customs == 1">
              <span v-if="info.declare_time">{{ info.declare_time | secondFormat("LL") }}</span>
              <span v-else>{{ $t("yes") }}</span>
            </p>
          </div>
          <div class="trailer">
            <span class="text1">{{ $t("needTrailer") }}</span>
            <span class="text2" v-if="info.is_trailer">{{$t('yes')}}</span>
            <span class="text2" v-else>{{$t('no')}}</span>
          </div>
        </div>
        <div class="remark">
          <div class="noun">
            <span class="text1">{{ $t("needStorage") }}</span>
            <span class="text2" v-if="info.is_storage">{{$t('yes')}}</span>
            <span class="text2" v-else>{{$t('no')}}</span>
          </div>
          <div class="insurance">
            <span class="text1">{{ $t("needInsurance") }}</span>
            <p v-if="!info.is_insurance">{{$t('no')}}</p>
            <p v-if="info.is_insurance==1&&info.insuranceStatus"></p>
            <p v-if="info.is_insurance==1&&!(info.insurance_info.coverage||info.insurance_info.invoice_value)">{{$t('yes')}}</p>
            <p
              v-if="info.insurance_info.coverage"
            >{{$t('Coverage')}}：{{info.insurance_info.coverage | coverageFormat(LOCALE)}}</p>
            <p
              v-if="info.insurance_info.invoice_value"
            >{{$t('ValueonCommercialInvoice')}}：{{info.insurance_info.invoice_value }} {{info.insurance_info.monetary_unit|currencyFormat(LOCALE)}}</p>
          </div>
          <div class="remarks">
            <span class="text1">{{ $t("notes") }}</span>
            <span class="text2">{{ info.remarks | textFormat }}</span>
          </div>
          <div class="attachment">
            <span class="text1">{{ $t("file") }}</span>
            <div v-if="info.attachment_list.length">
              <a
                class="text2"
                v-for="(item, index) in info.attachment_list"
                :key="index"
                :href="item.url"
                target="_blank"
              >
                <img
                  :src="item.file_type | enclosureImgbyTypeFormat"
                  alt
                  class="fileImg marginLess"
                />
                <span>{{ item.file_name }}</span>
              </a>
            </div>
            <div v-else>-</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isObjectAllPropEmpty } from "@/baseUtils";
export default {
  name: "inquiryDetail",
  layout: "inquiryCenter",
  props: {
    info: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  created() {
    if (isObjectAllPropEmpty(this.info.hazardous_info)) {
      this.info.hazardousStatus = false;
    } else {
      this.info.hazardousStatus = true;
    }
    if (isObjectAllPropEmpty(this.info.temperature_info)) {
      this.info.temperatureStatus = false;
    } else {
      this.info.temperatureStatus = true;
    }
    if (isObjectAllPropEmpty(this.info.insurance_info)) {
      this.info.insuranceStatus = false;
    } else {
      this.info.insuranceStatus = true;
    }
  },
  filters: {
    yesOrNo(value) {
      let str = "";
      if (value) {
        str = "Yes";
      } else {
        str = "No";
      }
      return str;
    }
  }
};
</script>

<style lang="less" scoped>
.inquirt_detail {
  width: 100%;
  background: rgba(255, 255, 255, 1);
  .inquiryDetail_line {
    border: 1px solid #ECECEC;
    // width: 100%;
    // height: 5px;
    // background: url("https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/images/inquiryDetailLine.png");

  }
  .inquiry_content {
    padding: 30px 69px;
    .title {
      font-size: 16px;
      font-weight: bold;
      color: rgba(51, 51, 51, 1);
      line-height: 22px;
    }
    .content-wrap {
      p {
        font-size: 14px;
      }
      > div {
        display: flex;
        box-sizing: border-box;
        margin-top: 30px;
        padding-bottom: 30px;
        border-bottom: 1px dashed #cbcbcb;
        div {
          display: flex;
          flex-direction: column;
          span:nth-child(1) {
            margin-bottom: 20px;
          }
        }
      }
      > div:last-child {
        border-bottom: none;
      }
      .departure,
      .delivery-time,
      .danger,
      .noun {
        width: 270px;
      }
      .destination,
      .arrival-time,
      .temperature,
      .insurance {
        width: 249px;
      }
      .name,
      .shipping-mode,
      .stackable,
      .remarks {
        width: 230px;
      }
      .weight,
      .pack,
      .customs {
        width: 262px;
      }
      .volume,
      .clause,
      .trailer {
        width: 157px;
      }
      .remarks {
        width: 228px;
        .text2 {
          width: 200px;
        }
      }
      .name{
        .text2{
          width: 220px;
        }
      }
      .pack{
        .text2{
          width: 250px;
        }
      }
    }
  }
}

.text1 {
  font-size: 14px;
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
  line-height: 20px;
}
.text2 {
  font-size: 14px;
  font-weight: 500;
  color: rgba(51, 51, 51, 1);
  line-height: 20px;
}
.fileImg {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  position: relative;
  top: -1px;
  &.marginLess {
    margin-right: 2px;
  }
}
.danger {
  p {
    width: 260px;
    a {
      word-wrap: break-word;
    }
  }
  .clickFile {
    font-size: 14px;
    margin-top: 1px;
    color: #333;
    word-wrap: break-word;
    word-break: break-all;
  }
}
.temperature {
  p {
    width: 230px;
  }
}
.insurance {
  p {
    width: 230px;
  }
}
.attachment {
  width: 420px;
  a {
    word-wrap: break-word;
    span {
      word-wrap: break-word;
      word-break: break-all;
    }
  }
}
.departure,
.destination {
  .text2 {
    width: 220px;
  }
}
</style>
